import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import AccordionWrap from '../../../components/ui/accordion'
import SectionTitle from '../../../components/ui/section-title'
import PopupVideo from '../../../components/ui/popup-video/layout-one'
import { Container, Box, Row, Col } from '../../../components/ui/wrapper'
import { SectionWrap } from './style'

const FaqSection = ({ accordionStyle }) => {
    const getFaqData = useStaticQuery(graphql`
        query GET_FAQ_SECTION_ONE_DATA {
            intro: faqJson(id: {eq: "section-one-itro"}) {
                title
            }
            faq: faqJson(id: {eq: "faq"}) {
                items {
                    id
                    question
                    answer
                }
            }
            video: faqJson(id: {eq: "faq-video"}) {
                image {
                    childImageSharp {
                        fluid(maxWidth: 570, maxHeight: 350, quality: 100, srcSetBreakpoints: 6) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
                video_link
            }
        }
    `)
    const { intro: { title }, faq: { items: faqs }, video: { image, video_link } } = getFaqData
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb="44px"
                            title={title}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <AccordionWrap {...accordionStyle}>
                            <Accordion allowZeroExpanded preExpanded={[0]}>
                                {
                                    faqs.map((el, index) => {
                                        return (
                                            <AccordionItem id={el.id} key={index}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {el.question}
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>{el.answer}</p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        )
                                    })
                                }
                            </Accordion>
                        </AccordionWrap>
                    </Col>
                    <Col lg={6}>
                        <Box className="ml-4 mr-5">
                        <h5 className="mb-3">Welcome to Triploaf.</h5>
                        <Col style={{fontSize:'1.24rem', lineHeight:'2.2rem', padding:'0' }}>We are an online travel agency and travel content provider. Our content covers people, places and culture around the world. We offer the ability to purchase accommodations, experiences, flights and other services related to travel via our Site. These products and services are provided by third party suppliers (for example hotels, tour operations and others). 
Here are a few of our most commonly asked questions about working with a travel agent. We're here to set your mind at ease, if you don't see your question here, send us an email at hello@mrgv.co, we’d love to talk to you!</Col>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

FaqSection.defaultProps = {
    accordionStyle: {
        responsive: {
            medium: {
                mb: '50px'
            }
        }
    }
}

export default FaqSection
